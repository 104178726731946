<template>
  <div class="container p-tb-2 cart-container">
    <div v-if="items.length" class="row py-5">
      <div v-if="errorMessage" class="col-lg-12 col-xs-12 mb-2 mx-2">
        <div class="error-msg large-font pb-2">{{ errorMessage }}</div>
      </div>
      <div v-if="!placed" class="col-lg-12 col-xs-12">
        <h1>Cart</h1>
        <div class="row" v-if="products">
          <template v-for="({ product: id, quantity, design }, i) in items">
            <div class="col-lg-12 col-xs-12 cart-item tile px-5" :key="i">
              <div class="tile-image mr-2 text-center" style="width: 70px">
                <img :src="getProductImage(id, design)" alt="" height="70" />
              </div>
              <router-link v-if="products" :to="`/product/${products[id].slug}`" class="text-color-purple underlined">
                {{ products[id].title }}
              </router-link>
              <router-link
                v-if="products && products[id].customizable"
                :to="`/constructor/${products[id].slug}`"
                class="button btn-small bg-light-green text-color-white text-bold uppercase pa-3 ml-4"
                style="max-width: max-content; font-size: small; height: 36px !important; border-radius: 3px;"
              >
                Customize
              </router-link>
              <span v-if="!products">Loading...</span>
              <div class="actions">
                <span class="mr-2">Quantity: </span>
                <input
                  type="number"
                  :min="products[id].minimalQty"
                  @change="e => changeQty(e, id)"
                  :value="quantity"
                  style="width: 100px"
                />
                <div
                  class="button btn-small bg-light-green text-color-white text-bold uppercase pa-3 ml-4"
                  style="border-radius: 3px; height: 36px !important;"
                  @click="remove(id)"
                >
                  Remove
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-if="!placed" class="col-lg-12 col-xs-12 p-0 p-xs-15px">
        <form @submit.prevent="submit">
          <div class="row">
            <FormField class="col-lg-8 col-xs-12">
              <template>
                <h2>Comment</h2>
                <textarea class="" v-model="comment" rows="6" />
              </template>
            </FormField>
            <div
              class="col-lg-3 p-xs-rl-05  col-xs-12 col-lg-offset-1"
              style="display: flex; flex-direction: column; justify-content: flex-end; padding: 5px 0"
            >
              <div v-if="!placing" class="rounded-5 w-100 medium-font button bg-gray text-medium" @click="clearCart">
                Clear cart
              </div>
              <button
                v-if="!placing"
                class="rounded-5 w-100 mt-2 text-medium medium-font button text-color-white bg-light-green text-medium"
                type="submit"
              >
                Place order
              </button>
              <div
                v-else
                class="rounded-5 w-100 text-medium medium-font button text-color-white bg-light-green loading-bg"
              >
                Please wait
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-else class="row py-5">
      <div v-if="placed" class="col-lg-12 text-center large-font">
        <div class="p-b-1">Thank you for your order, our manager will contact you soon</div>
        <router-link to="/orders" class="underlined text-color-purple">Go to the orders history</router-link>
      </div>
      <div v-else class="col-lg-12 text-center large-font">
        <div class="p-b-1">Your cart is empty yet</div>
        See our <router-link to="/vaporizers" class="underlined text-color-purple">product catalog</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import FormField from 'Components/FormField';
import { dataURItoBlob } from 'Lib/Utils';

export default {
  name: 'Cart',
  components: { FormField },
  data() {
    return {
      products: null,
      items: [],
      loading: false,
      placing: false,
      placed: false,
      comment: '',
      errorMessage: '',
    };
  },
  methods: {
    getProductImage(id, design) {
      const defImg = '/img/logo-small.svg';
      if (design) {
        return design.templates[0].image;
      } else if (this.products) {
        return this.products[id]?.image || defImg;
      } else {
        return defImg;
      }
    },
    changeQty(e, id) {
      const qty = +e.target.value;
      this.$store.dispatch('cartAdd', { id, qty });
    },
    remove(id) {
      this.$store.dispatch('cartRemove', id);
    },
    clearCart() {
      this.$store.dispatch('cartClear');
    },
    setLoader(state, prop = 'loading') {
      if (prop in this.$data) {
        this[prop] = state;
      }
      this.$store.dispatch('loader', state);
    },
    loadProducts(cartItems) {
      const ids = [];
      this.items = cartItems.map(([product, { qty: quantity, design }]) => {
        if (!(product in (this.products || {}))) {
          ids.push(product);
        }
        return { product, quantity, design };
      });
      if (ids.length) {
        this.setLoader(true);
        this.$api.getProductsCollection({ id: ids }).then(collection => {
          this.products = { ...this.products, ...Object.fromEntries(collection.items.map(item => [item.id, item])) };
          this.setLoader(false);
        });
      }
    },
    submit() {
      this.setLoader(true, 'placing');
      const designs = this.items
        .filter(el => !!el.design)
        .map(({ product, design }) => {
          return {
            product,
            templates: design.templates.map(tpl => {
              return { file: dataURItoBlob(tpl.image), jsonData: tpl.data };
            }),
          };
        });
      this.$api
        .saveCartDesigns(designs)
        .then(pools => {
          const items = this.items.map(({ product, quantity }) => {
            const data = { product, quantity };
            if (product in pools) {
              data.constructorPool = pools[product].pool_id;
            }
            return data;
          });
          this.$api
            .placeOrder({ comment: this.comment, items })
            .then(() => {
              this.setLoader(false, 'placing');
              this.clearCart();
              this.placed = true;
            })
            .catch(err => {
              console.log(err);
              this.setLoader(false, 'placing');
              let message;
              if (err.response?.data) {
                const { title, detail } = err.response.data;
                message = `${title}: ${detail}`;
              }
              this.errorMessage = message || err.message;
              this.errorMessage = err.message;
            });
        })
        .catch(err => {
          this.setLoader(false, 'placing');
          let message;
          if (err.response?.data) {
            const { title, detail } = err.response.data;
            message = `${title}: ${detail}`;
          }
          this.errorMessage = message || err.message;
          this.errorMessage = err.message;
        });
    },
  },
  created() {
    this.unsubCart = this.$store.subscribe(mutation => {
      if (mutation.type === 'updateCart') {
        this.loadProducts([...this.$store.state.cart.entries()]);
      }
    });
    this.loadProducts([...this.$store.state.cart.entries()]);
  },
  beforeDestroy() {
    this.unsubCart();
  },
};
</script>
